<template>
  <promised :promise="promise">
    <template #pending>
      <b-loading 
        :active="true"
        :is-full-page="false"
      />
    </template>
    <template #default="{ data }">
      <breadcrumb :items="breadcrumbs" />
      <hero>
        {{ data.name }}
        <router-link 
          slot="right" 
          to="/companies"
          class="button"
        >
          Companies
        </router-link>
      </hero>
      <section class="section is-main-section">
        <card
          :title="data.name"
          icon="pencil-outline"
        >
          <tabs>
            <template #items>
              <b-tab-item
                label="Data"
                icon="database"
              >
                <validation-observer 
                  v-slot="{ handleSubmit, pristine }"
                  ref="form"
                  tag="form"
                >
                  <input-with-validation
                    v-model="form.name"
                    name="name"
                    rules="required|max:255"
                    label="Name"
                    icon="nintendo-game-boy"
                    maxlength="255"
                    horizontal
                  />
                  <hr>
                  <b-field horizontal>
                    <b-field grouped>
                      <div class="control">
                        <b-button 
                          type="is-primary"
                          :disabled="pristine || loading"
                          @click="handleSubmit(editCompany)"
                        >
                          Submit
                        </b-button>
                      </div>
                    </b-field>
                  </b-field>
                </validation-observer>
              </b-tab-item>
            </template>
          </tabs>
        </card>
      </section>
      <b-loading 
        :active="loading" 
        :is-full-page="false" 
      />
    </template>
    <template #rejected>
      <section class="section is-main-section">
        <div class="content has-text-grey has-text-centered">
          <b-icon 
            icon="emoticon-sad" 
            size="is-large" 
          />
          <p>Something went wrong</p>
        </div>
      </section>
    </template>
  </promised>
</template>

<script>
import { mapActions } from "vuex";
import { Promised } from "vue-promised";
import { ValidationObserver } from "vee-validate";
import buildFormDataMixin from "@/mixins/buildFormDataMixin";
import Breadcrumb from "@/components/Breadcrumb";
import Hero from "@/components/Hero";
import Card from "@/components/Card";
import Tabs from "@/components/Tabs";
import InputWithValidation from "@/components/Validation/InputWithValidation";
import Repository from "@/repositories/Repository";

export default {
  name: 'CompaniesEdit',

  components: {
    Promised,
    ValidationObserver,
    Breadcrumb,
    Hero,
    Card,
    Tabs,
    InputWithValidation,
  },

  mixins: [buildFormDataMixin],

  data() {
    return {
      promise: null,
      breadcrumbs: [
        'Admin', 
        'Edit Company',
      ],
      loading: false,
      form: {},
    };
  },

  created() {
    this.promise = Repository.get('companies').find(this.$route.params.company, {
      state: 'preview',
    });
    this.promise.then(response => {
      this.form = response.data;
    });
  },

  methods: {
    ...mapActions('data', {
      editCompanyAction: 'editCompany',
    }),

    editCompany() {
      this.loading = true;
      this.editCompanyAction(this.buildFormData(Object.assign({}, this.form, {
        _method: 'PATCH',
      }))).then(() => {
        this.$buefy.toast.open({
          message: 'Edited successfully',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-warning',
          position: 'is-bottom-right',
        });
        if (err.response) {
          this.$refs.form.setErrors(err.response.data.errors);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
